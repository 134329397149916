import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Blog from '../../components/blog/en'
import LeftColumn from '../../components/blog/left-column/es'
import RightColumn from '../../components/blog/right-column/es'
import PostSummary from '../../components/blog/post-summary/es'
import PostIndex from '../../components/blog/post-index/es'
import ether from './Ethereum_logo_2014.svg'
import messages from '../../data/messages/es';

export default (props) => (
    <StaticQuery
    query={graphql`

    query {

      logo: file(relativePath: {eq: "components/blog/author/logo-manu.png"}) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            base64
            src
            width
            height
          }
        }
      }


    btc: file(relativePath: {eq: "pages/contact/BC_Logo_.png"}) {
            childImageSharp {
              fixed(width: 100, height: 100) {
                base64
                src
                width
                height
              }
            }
          }



    }


    `}

      render={
        data => {
          console.log(props);
          return (
       <Blog  {...props}>
    <LeftColumn {...props} >

      <div  className="card mb-4">
        <div className="card-body">
          <a className="entries-link" href={props.url} >
            <h1 className="text-center"> {messages.title} </h1>
            <h2 className="text-center entries-title"> {messages.subTitle}</h2>
          </a>
          <p className="text-justify p-2">

          Gracias por visitar mi sitio web. Escríbeme si crees que puedo ayudar en tu proyecto. Tengo experiencia en una amplia gama de disciplinas como blockchain,
          sistemas distribuidos, computación en la nube, etc. Para obtener una descripción detallada de mi perfil, visita el 
          <a href="https://docs.google.com/document/d/16nIfa13pOJqTf6s0ABA6elnCcKJmksHwZRf64qTyPD4/edit?usp=sharing"> siguiente enlace </a>. <br/>
          </p>
          <p className="text-justify p-2">
            Tengo disponibilidad por horas, días o semanas. Acepto bitcoin, etherium y monedas tradicionales . Puedes encontrarme en   <a href="mailto:info@backendhelpers.co">info@backendhelpers.co</a> 
          </p>
          <div className="text-center p-2 crypto" >
              <div className="p-2 ">
                   <img src={data.btc.childImageSharp.fixed.src} alt="bitcoin" className="img-fluid crypto"/>
                  <img src={ether} alt="etherium" className="crypto-ether"/> 
              </div>

          </div>
        </div>
      </div>


    </LeftColumn>

    <RightColumn {...props}>
      <PostIndex />
    </RightColumn>
  </Blog>)
        }
      }
    />





)

