import React from 'react';
import RightColumn from './base';
import messages from '../../../data/messages/es';

export default (props) => (
  <RightColumn
    {...props}
    locale='es'
    messages={messages}
  >
  </RightColumn>
);
