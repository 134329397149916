import React from 'react';
import PostIndex from './base';
import messages from '../../../data/messages/es';

export default (props) => (
  <PostIndex
    {...props}
    locale='es'
    messages={messages}
  >
  </PostIndex>
);
