module.exports = {
  selectLanguage: 'English',
  title: `Manuel Franco`,
  subTitle: `Consultor de Blockchain`,
  description: `Sitio Web Profesional`,
  author: `agrohelpers`,
  seeMore: `Ver más ...`,
  blogUrl: 'https://blog.backendhelpers.co',
  letsTalk: 'Hablemos',
  googleSiteVerification: '5wkCCvMC9CUD5XXW6WPtTWCZiTQG8GgnMz0m9VBf4KU' ,
  cookiesAlert: {
    title: 'Este Sitio Utiliza Cookies',
    subTitle: 'Nostros tomamos tu privacidad muy seriamente. Utilizamos cookies para analizar tu comportamiento cuando visitas nuestro sitio con el fin de mejorar continuamente',
    ok: 'Aceptar',
    reject: 'Salir'

  },
  postIndex: {
    title: 'Entradas Populares',
    posts: [
      {
        year: 2019,
        entries: [
          {
            title: 'Compilando el Código Fuente de Bitcoin Usando Docker',
            slug: '/es/2019/docker-alpine-bitcoin/',
            summary: true
          },
          {
            title: 'Introducción a HashiCorp Terraform',
            slug: '/es/2019/terraform-intro/'
          },
          {
            title: 'Introducción a AWS EC2 Usando Python',
            slug: '/en/2019/aws-ec2-python-intro/'
          }

        ]
      },
      {
        year: 2018,
        entries: [
          {
            title: 'Introducción a Google Cloud',
            slug: '/es/2018/google-cloud-intro/'
          },
          {
            title: 'Introducción a AWS SES',
            slug: '/es/2018/aws-ses-intro/'
          },
          {
            title: 'Introducción a AWS SQS',
            slug: '/es/2018/aws-sqs-intro/'
          },
          {
            title: 'Introducción a AWS EC2',
            slug: '/es/2018/aws-ec2-intro/'
          }
        ]
      },
      {
        year: 2017,
        entries: [
          {
            title: 'Introducción a la Billetera de Bitcoin',
            slug: '/es/2017/introduction-bitcoin-wallet'
          },
          {
            title: 'Algoritmo de los K Vecinos Más Cercanos (K-NN) Usando R ',
            slug: '/es/2017/k-nearest-neighbours-algorithm-knn-R'
          },
          {
            title: 'Introducción a Apache Spark Resilient Distributed Datasets (RDD)',
            slug: '/es/2017/spark-rdd-intro'
          },

        ]
      },

    ]
  }
};
